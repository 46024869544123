(function(){"use strict";class f{constructor(){this.data={}}set(t,e){this.data[t]=e}get(t){return this.data[t]}}const p={Booster:class{constructor(t){this.options=t}log(t,e){const o=`
      display: inline-block;
      padding: 4px 6px;
      border-radius: 4px;
      line-height: 1.5em;
      color: #282735;
      background: linear-gradient(45deg,
        rgba(185, 205, 255, 0.4) 0%,
        rgba(201, 182, 255, 0.4) 33%,
        rgba(239, 184, 255, 0.4) 66%,
        rgba(255, 210, 177, 0.4) 100%);
        `,i=[`%c[${this.options.title}] ${t}. Link to documentation ${this.options.documentationLink}`,o];e?(console.group(...i),Array.isArray(e)?console.log(...e):console.log(e),console.groupEnd()):console.log(...i)}validate(t,e,o){if(!t.validate)return!0;if(typeof t.validate=="function"){if(!t.validate(o))return this.log(`Invalid value "${o}" for attribute "${e}"`),!1}else if(!t.validate.includes(o))return this.log(`Invalid value "${o}" for attribute "${e}"`,[`%cPossible values:%c
`+t.validate.map(i=>`• ${i}`).join(`
`),"font-weight: 700;","font-weight: initial;"]),!1;return!0}parse(t){const e=new f;for(const o in this.options.attributes){const i=this.options.attributes[o],l=t.getAttribute(o);if(!l){e.set(o,i.defaultValue);continue}if(!this.validate(i,o,l))continue;let r=l;i.parse&&(r=i.parse(l)??i.defaultValue),e.set(o,r)}this.options.apply.call(this,t,e)}getElements(){return document.querySelectorAll(`[${this.options.name}]`)}init(){this.getElements().forEach(e=>this.parse(e))}},parse:{stringToBoolean:s=>s!=="false"},validation:{isBoolean:s=>/^(true|false)$/.test(s),isNumber:s=>!isNaN(Number(s))}};var a=(s=>(s.Visible="visible",s.Hidden="hidden",s))(a||{});const g=new p.Booster({name:"fb-password",attributes:{},apply(s){const t=s.getElementsByTagName("input")[0];if(!t)return this.log("Input element is missing");const e=Array.from(s.querySelectorAll("[fb-password-toggle]"));if(!e.length)return this.log('Required attribute "fb-password-toggle" is missing');const o=Array.from(s.querySelectorAll("[fb-password-state]")),i=[],l=[];for(const n of o){const d=n.getAttribute("fb-password-state"),c=d;Object.values(a).includes(c)?(c==="visible"&&i.push(n),c==="hidden"&&l.push(n)):this.log(`Invalid value "${d}" for attribute "fb-password-state"`,[`%cPossible values:%c
`+Object.values(a).map(h=>`• ${h}`).join(`
`),"font-weight: 700;","font-weight: initial;"])}const r=()=>{for(const n of i)n.style.display=t.type==="text"?"block":"none";for(const n of l)n.style.display=t.type==="text"?"none":"block"},b=()=>{t.type=t.type==="text"?"password":"text",r()};for(const n of e)n.addEventListener("click",b);r()},title:"Password Visibility Control Booster",documentationLink:"https://www.flowbase.co/booster/password-visibility-control"}),u=()=>g.init();document.readyState==="complete"?u():window.addEventListener("load",u)})();
